<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Litsenziya malumotlari</h3>
            </div>
          </div>
          <form>
            <div class="card-body">
              <div class="d-flex flex-wrap">
                <div class="col-4">
                  <label class="mb-4">Mashina raqami</label>
                  <v-autocomplete
                    v-model="getLicensesById.truck_info"
                    :items="allTrucks"
                    item-text="full__name"
                    item-value="id"
                    outlined
                    return-object
                    dense
                  ></v-autocomplete>
                </div>
                <div class="col-4">
                  <label>Reg raqami</label>
                  <input
                    type="text"
                    v-model="getLicensesById.reg_number"
                    placeholder="Raqam"
                    class="form-control form-control-lg"
                  />
                </div>
                <div class="col-4">
                  <label>Berilgan tashkilot nomi</label>
                  <input
                    type="text"
                    v-model="getLicensesById.given_by"
                    placeholder="Raqam"
                    class="form-control form-control-lg"
                  />
                </div>
                <div class="col-4">
                  <label>Boshlanish sanasi</label>
                  <v-dialog
                    ref="dialogGiven"
                    v-model="modalgiven"
                    :return-value.sync="given"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        label="given"
                        :value="getLicensesById.given | dateFilter"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="form-control form-control-lg"
                      />
                    </template>
                    <v-date-picker v-model="getLicensesById.given" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="modalgiven = false"
                        >Bekor qilish</v-btn
                      >
                      <v-btn
                        small
                        color="success"
                        @click="$refs.dialogGiven.save(given)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>
                <!-- <div class="col-4 pr-0"> -->
                <div class="col-4">
                  <label>Sertifikat turi</label>
                  <!-- <input
                  type="text"
                  v-model="getLicensesById.type"
                  placeholder="Turi"
                  class="form-control  form-control-lg"
                /> -->

                  <v-autocomplete
                    :items="licenceTypes"
                    v-model="getLicensesById.type"
                    item-text="name"
                    item-value="id"
                    :loading="isLoading"
                    outlined
                    return-object
                    dense
                  ></v-autocomplete>
                </div>
                <!-- </div> -->
                <div class="col-4">
                  <label>Tugash sanasi</label>
                  <v-dialog
                    ref="dialogExpires"
                    v-model="modalExpires"
                    :return-value.sync="expires"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        label="Start_date"
                        prepend-icon="event"
                        :value="getLicensesById.expires | dateFilter"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="form-control form-control-lg"
                      />
                    </template>
                    <v-date-picker v-model="getLicensesById.expires" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="modalExpires = false"
                        >Bekor qilish</v-btn
                      >
                      <v-btn
                        small
                        color="success"
                        @click="
                          $refs.dialogExpires.save(getLicensesById.expires)
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>

                <div class="col">
                  <b-button variant="success" class="w-100" @click="submit()">
                    Saqlash</b-button
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      full__name: '',
      start_date: '',
      given: '',
      expires: '',
      modal: false,
      modalExpires: false,
      modalStart_date: false,
      modalgiven: false
    }
  },
  beforeCreate() {
    this.$store.dispatch('getTruckLists')
    this.$store.dispatch('getLicenceTypes')
  },
  created() {
    this.$store.dispatch('getLicensesById', this.$route.params.id)
    this.currTuckId = this.$store.state.requests.truckListId.id
    this.start_date = this.$store.state.requests.licensesById.start_date
    this.expires = this.$store.state.requests.licensesById.expires
    this.given = this.$store.state.requests.licensesById.given
  },
  computed: {
    licenceTypes() {
      const data = this.$store.state.requests.licenceTypes
      return data
    },
    getLicensesById() {
      const data = this.$store.state.requests.licensesById
      if (data.truck_info !== undefined) data.truck_info = data.truck_info.id
      return data
    },
    allTrucks() {
      const dataNames = []

      this.$store.state.requests.allTruckList.forEach((element) => {
        const name = {
          full__name: element.reg_number_region + ' ' + element.reg_number,
          id: element.id
        }
        dataNames.push(name)
      })
      return dataNames
    }
  },
  methods: {
    submit() {
      const id = {
        id: this.$route.params.id
      }
      const data = {
        truck: this.getLicensesById.truck_info,
        reg_number: this.getLicensesById.reg_number,
        start_date: this.getLicensesById.given,
        given: this.getLicensesById.given,
        expires: this.getLicensesById.expires,
        type: this.getLicensesById.type.id,
        given_by: this.getLicensesById.given_by
      }
      this.$store.dispatch('updateLicenses', { id, data })
    }
  }
}
</script>

<style></style>
